import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_nav_bar = _resolveComponent("v-nav-bar")!
  const _component_v_fade_transition = _resolveComponent("v-fade-transition")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_footer = _resolveComponent("v-footer")!
  const _component_v_toast = _resolveComponent("v-toast")!
  const _component_v_loading = _resolveComponent("v-loading")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_nav_bar),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component, route }) => [
              _createVNode(_component_v_fade_transition, { mode: "out-in" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("div", {
                    key: route.path,
                    class: "h-100"
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ]))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_footer, { class: "flex-grow-0" }),
      _createVNode(_component_v_toast),
      _createVNode(_component_v_loading)
    ]),
    _: 1
  }))
}