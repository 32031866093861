import { Commit } from 'vuex';
import { IPanels } from '@/interfaces/IPanels';

const getDefaultState = () => {
  return {
    config: 0,
    score: 0,
    dashboard: 0,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    panels(state: IPanels) {
      return state;
    },
  },
  mutations: {
    setPanel(state: { [x: string]: number; }, panels: { [x: string]: number; }) {
      const prop = Object.keys(panels).filter(prop => panels[prop] !== state[prop]);
      state[prop as unknown as string] = panels[prop as unknown as string];
    },
  },
  actions: {
    setPanel({ commit }: { commit: Commit }, panels: object) {
      commit('setPanel', panels);
    },
  }
};
