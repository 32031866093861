const getDefaultState = () => {
  return {
    isLoading: false,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setLoading(state: { isLoading: boolean }, payload: boolean) {
      state.isLoading = payload;
    },
  },
};
