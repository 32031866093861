import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import '@/scss/variables.scss'

import { createVuetify, ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const customLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#00004E',
    secondary: '#C3B195',
    accent: '#0DBDBA',
  }
}

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'customLightTheme',
    variations: {
      colors: ['primary', 'secondary', 'accent', 'error', 'info', 'success', 'warning'],
      lighten: 5,
      darken: 5,
    },
    themes: {
      customLightTheme,
    }
  },
})
