import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const config = {
	apiKey: "AIzaSyChh3AQ4HYuDSRPmEO-fhppwljAeeg_j7A",
	authDomain: "omnichannel-3b80d1cf.firebaseapp.com",
	databaseURL: "https://omnichannel-3b80d1cf.firebaseio.com", // PROD
	// databaseURL:
	// 	"https://omnichannel-3b80d1cf-default-rtdb.europe-west1.firebasedatabase.app", // DEV/STAGE
	projectId: "omnichannel-3b80d1cf",
	storageBucket: "gcf-sources-170426517486-us-central1",
	messagingSenderId: "170426517486",
	appId: "1:170426517486:web:25482bfda1bb0f6cd130ff",
};

export const app = firebase.initializeApp(config);
export const storage = getStorage(app);
export const database = getDatabase(app);
export default firebase;
