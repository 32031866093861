
import { defineComponent, inject } from 'vue';
import { IToast } from '@/interfaces';

export default defineComponent({
  name: 'VToast',
  data() {
    return {
      show: false,
      message: '',
      timeout: 3000,
    }
  },
	setup() {
		const eventBus = inject("eventBus");
		return { eventBus };
	},
  created() {
    this.bindEvents();
  },
  methods: {
    bindEvents() {
      this.eventBus.on('toast', (data: IToast) => this.handleToast(data));
    },
    handleToast(data: IToast) {
      this.message = data.message || 'missing "message"';
      this.timeout = data.timeout || this.timeout;
      this.show = true;
    },
  }
});
