import Swal from 'sweetalert2';
import { IError } from '@/interfaces/IError';

export function errorHandler() {
  function onError(error: IError) {
    console.error(error);
    const { code, response, message } = error;

    if (response && response.status >= 400 && response.status < 405) {
      // HTTP response status codes.
      // Client error responses 400 - 404.
      return;
    }

    if (code) {
      if (code === 'auth/wrong-password') {
        displayErrorMessage('The password is incorrect');
        return;
      } else if (code === 'auth/user-not-found') {
        displayErrorMessage('The email address has not been found');
        return;
      } else {
        displayErrorMessage(message as string);
        return;
      }
    }

    displayErrorMessage(message as string);
    return;
  }

  function displayErrorMessage(message: string) {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      showCancelButton: false,
      showConfirmButton: true,
    });
  }

  return { onError };
}
