export function utils() {
  function formatDate(timestamp: number|string): string {
    return timestamp ? new Date(Number(timestamp)).toDateString() : 'Never';
  }

  function doubleRaf(callback: () => void) {
    requestAnimationFrame(() => {
      requestAnimationFrame(callback)
    })
  }

  return { formatDate, doubleRaf };
}
