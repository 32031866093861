
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import i18n from '@/plugins/i18n';

const { t } = i18n.global

export default defineComponent({
  name: 'VNavBar',
  data() {
    return {
      drawer: false,
      adminItems: [
        {
          icon: 'mdi-cog',
          to: '/config',
          title: t('routes.config'),
        },
        {
          icon: 'mdi-account-supervisor',
          to: '/facilitator',
          title: t('routes.facilitator'),
        },
        {
          icon: 'mdi-view-dashboard',
          to: '/dashboard',
          title: t('routes.dashboard'),
        },
      ],
      facilitatorItems: [
        {
          icon: 'mdi-view-dashboard',
          to: '/dashboard',
          title: t('routes.dashboard'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isAdmin', 'isFacilitator', 'user']),
    isUserWithRole() {
      return this.isAdmin || this.isFacilitator;
    },
  },
});
