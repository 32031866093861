
import { defineComponent } from 'vue';
import VNavBar from '@/components/NavBar.vue';
import VToast from '@/components/Toast.vue';
import VLoading from '@/components/Loading.vue';

export default defineComponent({
  name: 'app',
  components: {
    VNavBar,
    VToast,
    VLoading,
  },
});
