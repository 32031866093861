import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_v_overlay, {
        key: 0,
        "model-value": _ctx.isLoading,
        class: "align-center justify-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_progress_circular, {
            indeterminate: "",
            size: "64",
            color: "primary"
          })
        ]),
        _: 1
      }, 8, ["model-value"]))
    : _createCommentVNode("", true)
}