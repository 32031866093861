import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import auth from './auth';
import session from './session';
import panels from './panels';
import loading from './loading';

const store = createStore({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    session,
    panels,
    loading,
  },
});

export default store;
