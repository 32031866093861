import { Commit } from 'vuex';

const getDefaultState = () => {
  return {
    id: null
  }
}

export default {
  state: getDefaultState(),
  getters: {
    sessionId(state: { id: string; }) {
      return state.id;
    },
  },
  mutations: {
    setId(state: { id: string }, id: string) {
      state.id = id;
    },
    unsetId(state: { id: unknown }) {
      state.id = null;
    },
  },
  actions: {
    setId({ commit }: { commit: Commit }, id: string) {
      commit('setId', id);
    },
  }
};
